import React from "react"
import { DialogContainer } from "src/components/common/dialog-container"
import { NotificationsPendingInvites } from "src/components/dashboard/notifications/notifications-pending-invites"
import { JestTestId } from "src/constants/tests"
import { usePendingInvites } from "src/hooks/use-pending-invites"
import { InformationCircleOutlined } from "src/icons/information-circle-outlined"

import { InvitesBannerStyles } from "./invites-banner.styles"

export const InvitesBanner = () => {
    const [open, setOpen] = React.useState(false);
    const { pendingInvites } = usePendingInvites();

    const handleViewAllClick = () => {
        setOpen(true);
    }

    const handleDialogClose = () => {
        setOpen(false);
    }

    if (pendingInvites.length === 0) return null;

    return <>
        <InvitesBannerStyles.Container>
            <InformationCircleOutlined width='16px' height='16px' />   You have {pendingInvites.length} new {pendingInvites.length === 1 ? 'invite' : 'invites'} to review
            <InvitesBannerStyles.Button
                data-testid={JestTestId.InvitesBannerViewAllButton}
                onClick={handleViewAllClick}>
                View All
            </InvitesBannerStyles.Button>
        </InvitesBannerStyles.Container>
        <DialogContainer open={open} onClose={handleDialogClose}>
            <NotificationsPendingInvites onClose={handleDialogClose} />
        </DialogContainer>
    </>
}
import { LoanReviewStatus } from "src/backend";
import { useKanbanLoans } from "src/contexts/loans-kanban-context";

import { LoanListViewStyles } from "./loan-list-view.styles"
import { LoanListViewPhase } from "./loan-list-view-phase-card";
import { LoanListViewSearchResults } from "./loan-list-view-search-results.component";

export const LoanListView = () => {
    const { state, filterState, fuzzySearchLoans } = useKanbanLoans();
    const atLeastOneLoanHasReviewStatus = Object.values(state.loans ?? {}).some(statusLoans => statusLoans.some(loan => loan.reviewStatus !== LoanReviewStatus.IN_PROGRESS));

    return <LoanListViewStyles.Wrapper className="pt-8 h-full" data-hide-statuses={!atLeastOneLoanHasReviewStatus}>
        <div className="gap-8 flex flex-col container px-4 mx-auto pb-4 h-full">
            {filterState.filterQuery && <LoanListViewSearchResults
                isLender={state.isLender}
                loans={fuzzySearchLoans}
            />}

            {!filterState.filterQuery && state.statuses.map(status => {
                return (<LoanListViewPhase
                    isLender={state.isLender}
                    key={status.id}
                    phase={status}
                    loans={state.loans?.[status.id]} />)
            })}
        </div>
    </LoanListViewStyles.Wrapper>
}
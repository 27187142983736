import Icon from 'components/dist/atoms/Icon';
import Text from 'components/dist/atoms/Text';
import { memo } from "react";
import {useSubscription} from "react-stomp-hooks";
import { LoanPhaseCategoryType } from 'src/backend';
import { useUser } from 'src/hooks/use-user';
import { useGetUnreadCountByCompanyQuery } from "src/services/messageApi";

interface UnreadCompanyLoanMessagesCountProps {
    companyId: string;
    loanId: string;
}

export const UnreadCompanyLoanMessagesCount = memo((props: UnreadCompanyLoanMessagesCountProps) => {
    const userState = useUser()
    const categories = [
        LoanPhaseCategoryType.LEAD,
        LoanPhaseCategoryType.ORIGINATION,
        LoanPhaseCategoryType.PORTFOLIO,
    ]
    if (userState.isLender) {
        categories.push(LoanPhaseCategoryType.ARCHIVE)
    }
    const { data, isLoading, refetch } = useGetUnreadCountByCompanyQuery({
        companyId: props.companyId,
        categories
    }, {
        skip: !props.companyId,
    });

    useSubscription(`/topic/chats/${props.loanId}`, () => {
        refetch();
    });

    const count = data?.unreadMap?.[props.loanId] ?? 0;

    if (!count || isLoading) return null;

    return <Text as="div" variant="secondary" size="xs" className="text-gray-neutral-70 inline-flex gap-1 flex items-center">
        {count} <Icon name="MessageText" width={18} height={18} strokeWidth={1.5} />
    </Text>
});

UnreadCompanyLoanMessagesCount.displayName = 'UnreadCompanyLoanMessagesCount';
import { useCustomizedTable } from 'components/dist/organisms/CustomizedTable';
import { CustomizedTableHeader } from 'components/dist/organisms/CustomizedTable/types';
import { createContext, ReactNode, useContext, useEffect } from 'react';
import { useLoanListViewTable } from 'src/components/loans/loan-list-view/loan-list-view-table.hook';
import { BorrowerListViewColumnsDefaultOrder, LoanListViewColumnsDefaultOrder } from 'src/constants/loan';
import { KeyStorage, OrderDirection } from 'src/constants/ui';
import { State } from 'src/contexts/loans-kanban-context';
import { useKeyStorage } from 'src/hooks/use-key-storage';
import { LoanDisplayView, LoanSortBy } from 'src/types/loan';

export type TableColumnsValues = {
    columns: CustomizedTableHeader[];
};

const CustomizedTableContext = createContext<{
    allHeaders: CustomizedTableHeader[];
    selectedHeaders: CustomizedTableHeader[];
    toggleHeader: (header: CustomizedTableHeader) => void;
    onReset: () => void;
}>({
    allHeaders: [],
    selectedHeaders: [],
    toggleHeader: () => { },
    onReset: () => { },
});

export const CustomizedTableProvider = (props: { children: ReactNode }) => {
    const { columns, isLender } = useLoanListViewTable();
    const defaultColumnsOrder = isLender ? LoanListViewColumnsDefaultOrder : BorrowerListViewColumnsDefaultOrder;
    const {
        setValue: saveRemoteSettings,
        value,
        isLoading,
    } = useKeyStorage<TableColumnsValues>(KeyStorage.TableColumns, {
        columns: [],
    });
    const { value: kanbanSettings, setValue: saveKanbanSettings } =
        useKeyStorage<
            Pick<
                State,
                | 'displayView'
                | 'sortBy'
                | 'sortedListViewColumns'
                | 'orderDirection'
                | 'orderByColumn'
            >
        >(KeyStorage.KanbanSetting, {
            displayView: LoanDisplayView.grid,
            sortBy: LoanSortBy.newest,
            orderDirection: OrderDirection.Asc,
            orderByColumn: null,
            columnsOrder: defaultColumnsOrder,
        });
    const { allHeaders, selectedHeaders, setSelectedHeaders, toggleHeader } =
        useCustomizedTable({
            columns,
        });

    useEffect(() => {
        if (!isLoading && value.columns.length > 0) {
            setSelectedHeaders(value.columns.filter((header) => header));
        }
    }, [value.columns.length, isLoading, setSelectedHeaders]);

    const saveHeader = (header: CustomizedTableHeader) => {
        const updatedColumns = toggleHeader(header);
        saveRemoteSettings({ columns: updatedColumns });

    };

    const onReset = () => {
        const orderedHeaders = defaultColumnsOrder.map((id) => columns.find((header) => header.id === id));
        const updatedHeaders = setSelectedHeaders(orderedHeaders.slice(0, isLender ? orderedHeaders.length : 7));
        saveRemoteSettings({ columns: updatedHeaders });
        saveKanbanSettings({
            ...kanbanSettings,
            sortedListViewColumns: defaultColumnsOrder,
        });
    };

    return (
        <CustomizedTableContext.Provider
            value={{
                allHeaders: allHeaders.filter((header) => header),
                selectedHeaders: selectedHeaders.filter((header) => header),
                onReset,
                toggleHeader: saveHeader,
            }}
        >
            {props.children}
        </CustomizedTableContext.Provider>
    );
};

export const useCustomizedTableValues = () => {
    return useContext(CustomizedTableContext);
};

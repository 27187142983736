import { styled } from '@mui/material/styles';
import { themeV2 } from "src/theme/mysherpas-theme-option"

const Container = styled("div")(({ theme }) => ({
    backgroundColor: themeV2.colors.green[20],
    padding: theme.spacing(2),
    justifyContent: "center",
    display: 'flex',
    textAlign: 'center',
    alignItems: 'center',
    gap: theme.spacing(1.5),
    fontSize: theme.typography.pxToRem(12),
    color: themeV2.colors.blue[900],
    fontWeight: 400,
}))

const Button = styled("button")(({ theme }) => ({
    all: "unset",
    backgroundColor: themeV2.colors.blue[800],
    fontSize: theme.typography.pxToRem(12),
    height: 24,
    color: 'white',
    minWidth: 96,
    borderRadius: 6,
    cursor: 'pointer',
}))

export const InvitesBannerStyles = {
    Container,
    Button
}